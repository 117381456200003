import {defineStore} from 'pinia'
import xhr from "@/utils/xhr";
import {NotificationStores} from "@/stores/NotificationStores";
import {enumeration} from "../../Enumeration";
import {jsonValidator} from "../utils/JsonValidator";
import {t} from "@/utils/Traduction";
import {utils} from "@/utils/other";
import converter from "@/utils/Converter";

export const CommandeStores = defineStore("CommandesStores", {
    state: () => ({
        commandes: [],
        commandesLoading: true,
        commandesOffset: 0,
        hasMore: true,
        commandeSelected: null,
        unpayedCommandes: [],
        unpayedAmount: 0,
        isDownloadMultiFactureLoading: false
    }),
    actions: {
        async downloadMultiOrderInvoice(uuids) {
            this.isDownloadMultiFactureLoading = true;

            const notificationStore = NotificationStores();

            xhr.addRequestToQueue("POST", "/commandes/pdf/multi", {uuids: uuids}, true, true, true,
              (response) => {
                  if(response.code !== 200) {

                      if(response.code === 0 || !jsonValidator.validate(response.response)) {
                          notificationStore.addNotification(
                            t("errorDuringDownloadInvoice"),
                            "error");
                      } else {
                          notificationStore.addNotification(
                            JSON.parse(response.response).message,
                            "error");
                      }
                  } else {
                      const blob = new Blob([response.response], {type: 'application/pdf'});

                      const url = window.URL.createObjectURL(blob);
                      const link = document.createElement('a');
                      link.href = url;

                      const fileName = "commandes_" + new Date().toLocaleDateString().split('/').join('-') + ".pdf";

                      link.setAttribute('download', fileName);
                      document.body.appendChild(link);
                      link.click();
                      document.body.removeChild(link);

                      this.isDownloadMultiFactureLoading = false;
                  }
              });
        },
        async loadUnpayed() {
            const notificationStore = NotificationStores();

            xhr.addRequestToQueue("GET", "/commandes/unpayed", undefined, true, true, false,
                (response) => {
                    if(response.code !== 200) {

                        if(response.code === 0 || !jsonValidator.validate(response.response)) {
                            notificationStore.addNotification(
                                t("errorDuringGetOrders"),
                                "error");
                        } else {
                            notificationStore.addNotification(
                                JSON.parse(response.response).message,
                                "error");
                        }
                    } else {
                        const body = JSON.parse(response.response);

                        this.unpayedAmount = body.prixTotal;
                        this.unpayedCommandes = body.commandes;
                    }
                });
        },
        async getCommandeFromOffsetAdmin(offset, uuidUserFilter, uuidEtatFilter) {
            const notificationStore = NotificationStores();

            xhr.addRequestToQueue("GET", `/commandes/${offset}/all`, {uuidUserFilter, uuidEtatFilter}, true, true, false,
            (response) => {
                if(response.code !== 200) {

                    if(response.code === 0 || !jsonValidator.validate(response.response)) {
                        notificationStore.addNotification(
                            t("errorDuringGetOrders"),
                            "error");
                    } else {
                        notificationStore.addNotification(
                            JSON.parse(response.response).message,
                            "error");
                    }
                } else {
                    const newCommandes = JSON.parse(response.response);
                    this.commandes = this.commandes.concat(newCommandes);

                    if(newCommandes.length < 20) {
                        this.hasMore = false;
                    }

                    this.commandesLoading = false;
                }
            });
        },

        async getCommandeFromOffset() {
            const notificationStore = NotificationStores();

            xhr.addRequestToQueue("GET", `/commandes/${this.commandesOffset}`, undefined, true, true, false,
            (response) => {
                if (response.code !== 200) {

                    if (response.code === 0 || !jsonValidator.validate(response.response)) {
                        notificationStore.addNotification(
                            t("errorDuringGetOrders"),
                            "error");
                    } else {
                        notificationStore.addNotification(
                            JSON.parse(response.response).message,
                            "error");
                    }
                } else {
                    const newCommandes = JSON.parse(response.response);
                    this.commandes = this.commandes.concat(newCommandes);

                    this.commandesOffset += 20;

                    if (newCommandes.length < 20) {
                        this.hasMore = false;
                    }

                    this.commandesLoading = false;
                }
            });
        },

        async getCommandeById(uuid) {
            const notificationStore = NotificationStores();

            xhr.addRequestToQueue("GET", `/commandes/${uuid}/detail`, undefined, true, true, false,
            (response) => {
                if(response.code !== 200) {

                    if(response.code === 0 || !jsonValidator.validate(response.response)) {
                        notificationStore.addNotification(
                            t("errorDuringGetOrder"),
                            "error");
                    } else {
                        notificationStore.addNotification(
                            JSON.parse(response.response).message,
                            "error");
                    }

                    return null;
                } else {
                    this.commandeSelected = JSON.parse(response.response);
                }
            });
        },

        resetSelected() {
            this.commandeSelected = {notLoad: true, id:"", nom: "", etat: {id: "", libelle: "", notLoad: true}, produits: [], dateDebut: "", utilisateur: {email: "", livraison: ""}, cout: {total: "", production: ""}, PrixBobine: "", PrixElectricite: ""};

            for (let i = 0; i < 4; i++) {
                this.commandeSelected.produits.push({notLoad: true, nom: "", nombre: "", poid: "", temp: "", couleur: {libelle: ""}, etat: {notLoad: true, libelle: ""}, PrixBobine: "", PrixElectricite: ""});
            }
        },

        updateProduitInCommandeSelected(produit) {
            let indexProduit = -1;
            for(let i = 0; i < this.commandeSelected.produits.length; i++) {
                if(this.commandeSelected.produits[i].uuid === produit.uuid) {
                    indexProduit = i;
                    break;
                }
            }

            if(indexProduit === -1) {
                const notificationStore = NotificationStores();
                notificationStore.addNotification(
                    t("pleaseRefreshPage"),
                    "error");
            } else {
                this.$state.commandeSelected.produits[indexProduit] = produit;
            }

            let allProduitIsDone = true;
            let oneIsWipOrDone = false;
            let allProduitIsCancel = true;

            for(let i = 0; i < this.commandeSelected.produits.length; i++) {
                if(this.commandeSelected.produits[i].etat.libelle !== enumeration.etatObjet.IMPRIMEE && this.commandeSelected.produits[i].etat.libelle !== enumeration.etatObjet.ANNULE) {
                    allProduitIsDone = false;
                }

                if(this.commandeSelected.produits[i].etat.libelle !== enumeration.etatObjet.ANNULE) {
                    allProduitIsCancel = false;
                }

                if(this.commandeSelected.produits[i].etat.libelle === enumeration.etatObjet.IMPRIMEE
                    || this.commandeSelected.produits[i].etat.libelle === enumeration.etatObjet.EN_IMPRESSION) {
                    oneIsWipOrDone = true;
                }
            }

            const indexCommandeInCommandes = this.commandes.findIndex((commande) => commande.uuid === this.commandeSelected.uuid);
            if(allProduitIsCancel) {
                this.$state.commandeSelected.etat.libelle = enumeration.etatCommande.ANNULEE;

                if(indexCommandeInCommandes !== -1) {
                    this.$state.commandes[indexCommandeInCommandes].etat.libelle = enumeration.etatCommande.ANNULEE;
                }
            } else if(allProduitIsDone) {
                const newEtat = this.$state.commandeSelected.estGratuite ? enumeration.etatCommande.TERMINEE_ET_PAYEE : enumeration.etatCommande.TERMINEE;

                this.$state.commandeSelected.etat.libelle = newEtat;

                if(indexCommandeInCommandes !== -1) {
                    this.$state.commandes[indexCommandeInCommandes].etat.libelle = newEtat;
                }

            } else if(oneIsWipOrDone) {
                this.$state.commandeSelected.etat.libelle = enumeration.etatCommande.EN_COURS;

                if(indexCommandeInCommandes !== -1) {
                    this.$state.commandes[indexCommandeInCommandes].etat.libelle = enumeration.etatCommande.EN_COURS;
                }
            }

            if(this.commandeSelected.etat.libelle === enumeration.etatCommande.TERMINEE) {
                this.$state.commandeSelected.cout = {
                    total: converter.round(utils.calculatePrixTotalCommande(this.commandeSelected)),
                    production: converter.round(utils.calculatePrixProductionCommande(this.commandeSelected))
                }
            }
        },

        updateCommandeStatusBySelected(status) {
            const indexCommandeInCommandes = this.commandes.findIndex((commande) => commande.uuid === this.commandeSelected.uuid);

            if(indexCommandeInCommandes !== -1) {
                this.commandes[indexCommandeInCommandes].etat.libelle = status;
            }

            this.commandeSelected.etat.libelle = status;
        },

        updateCommandeIsFreeBySelected(isFree) {
            const indexCommandeInCommandes = this.commandes.findIndex((commande) => commande.uuid === this.commandeSelected.uuid);

            if(indexCommandeInCommandes !== -1) {
                this.commandes[indexCommandeInCommandes].estGratuite = isFree;
            }

            this.commandeSelected.estGratuite = isFree;
        }
    },
    getters : {}
});
