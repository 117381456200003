<template>
  <ModalSuppresionInformationComponent v-if="informationStore.showModalDelete" :close-modal="closeModalDelete" />

  <div class="flex flex-col lg:flex-row items-between gap-4 m-4">
    <textarea :placeholder="t('informationMessage')" class="border border-black px-2 rounded"
              v-model="newMessage"></textarea>
    <input type="datetime-local" v-model="newEndDate" class="border border-black px-2 rounded" />
    <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" v-on:click="createInformation">{{ t('addAction') }}</button>
  </div>

  <div class="flex flex-row gap-16 justify-evenly flex-wrap my-16">
    <InformationCard v-for="information in informationStore.informations" :key="information.uuid" :information="information" :show-modal-delete="showModalDelete" />
  </div>

  <span v-if="informationStore.informations.length === 0" class="absolute left-1/2 top-1/2 translate-x-[-50%] translate-y-[-50%] text-3xl text-center">
    {{t("noInformation")}}
  </span>
</template>

<script setup>

import {UtilisateurStores} from "@/stores/UtilisateurStores";
import {router} from "@/routeur";
import {NotificationStores} from "@/stores/NotificationStores";
import {enumeration} from "../../Enumeration";
import {t} from "@/utils/Traduction";
import {InformationStore} from "@/stores/InformationStore";
import InformationCard from "@/components/InformationCard.vue";
import ModalSuppresionInformationComponent from "@/components/ModalSuppresionInformationComponent.vue";
import {ref} from "vue";

const utilisateurStore = UtilisateurStores();
const notificationStores = NotificationStores();

if(utilisateurStore.role !== enumeration.role.ADMIN) {
  utilisateurStore.logout(false);
  notificationStores.addNotification(t("youNeedToBeAdminToAccessThisPage"), 'error');
  router.push({ path: "/" });
}

const informationStore = InformationStore();

informationStore.loadInformations();

const uuidInformationModale = ref(0);

const closeModalDelete = (supprimer) => {
  if(supprimer) {
    informationStore.deleteInformation(uuidInformationModale.value);
  }

  informationStore.showModalDelete = false;
}

const showModalDelete = (uuid) => {
  uuidInformationModale.value = uuid;
  informationStore.showModalDelete = true;
}

const newMessage = ref("");
const newEndDate = ref("");

const createInformation = () => {
  if(newMessage.value === "") {
    notificationStores.addNotification(t("messageCannotBeEmpty"), 'error');
    return;
  }

  if(newEndDate.value === "") {
    newEndDate.value = null;
  }

  informationStore.createInformation(newMessage.value, newEndDate.value);
  newMessage.value = "";
  newEndDate.value = "";
}

</script>

<style scoped>

</style>