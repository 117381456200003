<template>
  <InformationsBar v-show="information.show === true" v-for="information in informationStore.activeInformations" :key="information.uuid" :information="information" />

  <div class="flex flex-col lg:flex-row justify-between items-center mt-8 mx-4">
    <div class="flex flex-col md:flex-row md:items-center">
      <h2 class="text-lg font-semibold">{{t("filter")}}</h2>

      <select
          @change="fetchCommandes(true)"
          v-model="userSelectedFilter"
          id="marque"
          class="px-3 py-2 block rounded border border-black md:mt-4 mb-4 lg:my-0 md:mx-4">
        <option value="null" selected>{{t("allClient")}}</option>
        <option v-for="client of utilisateurStore.clients" :key="client" :value="client.uuid">{{ client.email }}</option>
      </select>

      <select
          @change="fetchCommandes(true)"
          v-model="etatSelectedFilter"
          id="marque"
          class="px-3 py-2 block rounded border border-black md:mt-4 mb-4 lg:my-0 md:mx-4">
        <option value="null" selected>{{t("allStatus")}}</option>
        <option v-for="etat of etatStore.etats" :key="etat" :value="etat.uuid">{{ etat.libelle }}</option>
      </select>
    </div>

    <button v-show="!isMultiOrderInvoceMode" v-on:click="startNewMultiOrderInvoice" class="bg-blue-500 text-white px-4 py-1 rounded-md">{{t("createInvoiceMultiOrder")}}</button>
    <button v-show="isMultiOrderInvoceMode" v-on:click="downloadNewMultiOrderInvoice" class="bg-blue-500 text-white px-4 py-1 rounded-md">
      <span v-show="!commandeStores.isDownloadMultiFactureLoading">{{selectedMultiOrderInvoce.length}} {{t("forInvoice")}}</span>
      <loading-dot v-show="commandeStores.isDownloadMultiFactureLoading" />
    </button>
  </div>

  <div v-show="commandeStores.commandes.length === 0 && !commandeStores.commandesLoading" class="flex justify-center items-center mt-32">
    <h2 class="text-3xl font-semibold text-center">{{t("noOrderCorresponding")}}</h2>
  </div>

  <div class="flex flex-row flex-wrap justify-evenly md:my-4 mx-4 md:mx-0">
    <id-card-commande v-show="commandeStores.commandesLoading" v-for="i in 20" :key="i" :commande="{etat: {libelle: ''}, utilisateur: {email: '', livraison: ''}}" />
    <id-card-commande :toogleOrderToMultiOrderInvoice="toogleOrderToMultiOrderInvoice" :isMultiOrderInvoceMode="isMultiOrderInvoceMode" v-for="commande of commandeStores.commandes" :commande="commande" :key="commande"/>
  </div>

  <div class="flex flex-row justify-center">
    <button v-show="commandeStores.hasMore && commandeStores.commandes.length > 0" @click="fetchCommandes(false)" class="bg-blue-500 text-white p-3 rounded-xl w-1/2 mb-8 mt-4">{{t("showMore")}}</button>
  </div>
</template>

<script setup>

import IdCardCommande from "@/components/IdCardCommande";
import {CommandeStores} from "@/stores/CommandeStores";
import {router} from "@/routeur";
import {UtilisateurStores} from "@/stores/UtilisateurStores";
import {NotificationStores} from "@/stores/NotificationStores";
import {enumeration} from '../../Enumeration';
import {t} from "../utils/Traduction";
import {InformationStore} from "@/stores/InformationStore";
import InformationsBar from "@/components/InformationsBar.vue";
import {ref} from "vue";
import {EtatStore} from "@/stores/EtatStore";
import LoadingDot from "@/components/LoadingDot.vue";

const utilisateurStore = UtilisateurStores();
const notificationStore = NotificationStores();
const informationStore = InformationStore();
const etatStore = EtatStore();

if(utilisateurStore.role !== enumeration.role.ADMIN) {

  utilisateurStore.logout(false);
  notificationStore.addNotification(t("youNeedToBeAdminToAccessThisPage"), 'error');
  router.push({ path: "/"});
}

const commandeStores = CommandeStores();

informationStore.loadActiveInformations();
utilisateurStore.loadClients();
etatStore.loadEtatsCommandes();

const userSelectedFilter = ref("null");
const etatSelectedFilter = ref("null");
const offset = ref(0);

const isMultiOrderInvoceMode = ref(false);
const selectedMultiOrderInvoce = ref([]);

const startNewMultiOrderInvoice = () => {
  isMultiOrderInvoceMode.value = true;
  selectedMultiOrderInvoce.value = [];
}

const toogleOrderToMultiOrderInvoice = (uuid) => {
  if(selectedMultiOrderInvoce.value.includes(uuid)) {
    selectedMultiOrderInvoce.value = selectedMultiOrderInvoce.value.filter((value) => value !== uuid);
  } else {
    selectedMultiOrderInvoce.value.push(uuid);
  }
}

const downloadNewMultiOrderInvoice = async () => {

  if(selectedMultiOrderInvoce.value.length === 0) {
    notificationStore.addNotification(t("noOrderSelected"), 'error');
    return;
  }

  await commandeStores.downloadMultiOrderInvoice(selectedMultiOrderInvoce.value);
  isMultiOrderInvoceMode.value = false;
  selectedMultiOrderInvoce.value = [];
}

const fetchCommandes = (reset) => {

  if(reset === true) {
    offset.value = 0;
    commandeStores.commandes = [];
    commandeStores.hasMore = true;
  }

  commandeStores.getCommandeFromOffsetAdmin(offset.value, userSelectedFilter.value, etatSelectedFilter.value);
  offset.value += 20;
}

if(commandeStores.commandes.length === 0) {
  fetchCommandes(true)
}


</script>

<style scoped>

</style>