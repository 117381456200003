<template>
  <div class="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
    <div class="bg-white shadow-xl w-[90vw] md:w-[70vw] lg:w-[60vw] max-h-[90vh] p-4 flex flex-col overflow-y-auto">
      <div v-show="step === 1" class="w-full flex flex-col items-center">
        <span class="text-lg my-4">{{ t("numberObjetAvailable") }} : {{ props.nbProduit - totalNbObjetTotalAttribuer }}</span>

        <div class="flex flex-col md:flex-row md:flex-wrap gap-2 md:gap-4 justify-center items-center mb-4 w-full overflow-y-auto">
          <div v-for="bobine of bobinesComplete" :key="bobine" class="w-[95%] md:w-[45%] lg:w-1/4">
            <div class="flex flex-row justify-between items-center">
              <span>
                {{ bobine.bobine.nombre }} - {{ bobine.bobine.couleur.libelle }} - {{ bobine.bobine.poidsRestant }} :
              </span>

              <div class="flex items-center border border-blue-500 rounded-full overflow-hidden mt-2 w-32 md:w-24 lg:w-32 h-10">
                <button @click="removeOne(bobine.bobine.uuid)" class="w-10 h-10 text-white bg-blue-500 hover:bg-blue-800 focus:outline-none">-</button>
                <span class="w-12 text-center bg-white text-black">{{ bobine.nbObjet }}</span>
                <button @click="addOne(bobine.bobine.uuid)" class="w-10 h-10 text-white bg-blue-500 hover:bg-blue-800 focus:outline-none">+</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-show="step === 2" class="w-full flex flex-col items-center">
        <span class="text-lg my-4"
          :class="{
            'text-red-500': (props.poidProduit * (props.nbProduit - totalNbObjetTotalAttribuer)) - totalPoidPartielAttribuer < 0,
          }"
        >{{ t("remainingWeight") }} : {{ (props.poidProduit * (props.nbProduit - totalNbObjetTotalAttribuer)) - totalPoidPartielAttribuer }}</span>

        <div class="flex flex-col md:flex-row flex-wrap gap-4 justify-center items-center mb-4 w-full overflow-y-auto">
          <div v-for="bobine of bobinesPartiel" :key="bobine" class="w-[95%] md:w-[45%] lg:w-[24%]">
            <div class="flex flex-row justify-between items-center">
              <span class="w-3/4 md:w-1/2">
                {{ bobine.bobine.nombre }} - {{ bobine.bobine.couleur.libelle }} - {{ bobine.bobine.poidsRestant }} :
              </span>

              <input v-model="bobine.poids" type="number" class="w-1/4 md:w-1/3 border border-black rounded px-2" min="0" />
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-row justify-center w-full gap-4 mt-8">
        <button v-show="step !== 1" @click="goToPreviousStep()" class="bg-blue-500 text-white p-2 rounded">{{ t("previous") }} ←</button>
        <button @click="cancel" class="bg-blue-500 text-white p-2 rounded">{{ t("cancel2") }}</button>
        <button v-show="peutValider" @click="validate" class="bg-blue-500 text-white p-2 rounded">{{ t("validate") }}</button>
        <button v-show="props.nbProduit - totalNbObjetTotalAttribuer !== 0 && step !== 2" @click="step++" class="bg-blue-500 text-white p-2 rounded">{{ t("next") }} →</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineExpose, defineProps, reactive, ref } from 'vue';
import { t } from "@/utils/Traduction";

const props = defineProps({
  nbProduit: {
    type: Number,
    required: true
  },
  poidProduit: {
    type: Number,
    required: true
  },
  bobinesDeLaCouleur: {
    type: Array,
    required: true
  },
  closeModal: {
    type: Function,
    required: true
  }
});

const step = ref(1);

const bobinesComplete = reactive(
    props.bobinesDeLaCouleur.map(bobine => ({ bobine, nbObjet: 0 }))
);

const bobinesPartiel = reactive(
    props.bobinesDeLaCouleur.map(bobine => ({ bobine, poids: 0 }))
);

const totalNbObjetTotalAttribuer = computed(() => {
  return bobinesComplete.reduce((total, bobine) => total + bobine.nbObjet, 0);
});

const totalPoidPartielAttribuer = computed(() => {
  return bobinesPartiel.reduce((total, bobine) => total + bobine.poids, 0);
});

const peutValider = computed(() => {
  return totalNbObjetTotalAttribuer.value === props.nbProduit ||
      totalPoidPartielAttribuer.value == props.poidProduit * (props.nbProduit - totalNbObjetTotalAttribuer.value);
});

const reset = () => {
  bobinesComplete.forEach(bobine => bobine.nbObjet = 0);
  bobinesPartiel.forEach(bobine => bobine.poids = 0);
}

defineExpose({ reset });

const addOne = (uuid) => {
  const bobine = bobinesComplete.find(bobine => bobine.bobine.uuid === uuid);

  // on utilise != car un est string et l'autre est nombre
  if(bobine && totalNbObjetTotalAttribuer.value != props.nbProduit) {
    bobine.nbObjet += 1;
  }
}

const removeOne = (uuid) => {
  const bobine = bobinesComplete.find(bobine => bobine.bobine.uuid === uuid);

  if(bobine && bobine.nbObjet > 0) {
    bobine.nbObjet -= 1;
  }
}

const cancel = () => {
  props.closeModal();
}

const validate = () => {
  const full = bobinesComplete.filter(bobine => bobine.nbObjet > 0);
  const partial = bobinesPartiel.filter(bobine => bobine.poids > 0);
  const selected = {full, partial}

  props.closeModal(selected);
}

const goToPreviousStep = () => {

  if(step.value === 2) {
    bobinesPartiel.forEach(bobine => bobine.poids = 0);
  }

  step.value--;
}

</script>

<style scoped>
</style>