<template>
  <div class="fixed inset-0 flex items-center justify-center z-50 mx-4 md:mx-0">
    <div class="bg-white max-w-md p-8 rounded shadow-xl md:w-2/3 lg:w-auto">
      <h2 class="text-2xl font-semibold mb-4">{{t("createARepair")}}</h2>

      <input v-model="newLibelle" type="text" :placeholder="t('repairLabel')" class="p-3 w-full rounded border mb-4" />
      <input v-model="newPrix" min="0" type="number" :placeholder="t('price')" class="p-3 w-full rounded border mb-4" />

      <div class="flex flex-row-reverse justify-between">
        <button @click="createRepair" class="bg-blue-500 text-white p-2 rounded w-full">{{t("validate")}}</button>
        <button @click="() => closeModal(undefined)" class="mt-2 p-2 text-gray-700 w-full">{{t("cancelAction")}}</button>
      </div>
    </div>
  </div>
</template>

<script setup>

import {ref, defineProps} from "vue";
import {t} from "../utils/Traduction";
import {NotificationStores} from "@/stores/NotificationStores";

const notificationStore = NotificationStores();

const props = defineProps({
  closeModal: Function
});

const newLibelle = ref("");
const newPrix = ref(0);

const createRepair = () => {
  if(newLibelle.value === "" || newPrix.value === 0) {
    notificationStore.addNotification(t("needFillAllFields"), 'error');
    return;
  }

  props.closeModal({libelle: newLibelle.value, prix: newPrix.value});
}

</script>

<style scoped>

</style>