<template>
  <nav class="bg-primary p-4 flex items-center justify-between">
    <div class="text-white text-xl font-bold">Market I3D</div>
    <div class="md:hidden">
      <button @click="toggleMenu" class="text-white hover:text-hover-white">
        <font-awesome-icon icon="fas fa-bars" class="text-lg"/>
      </button>
    </div>

    <div class="hidden md:flex space-x-4 items-center">
      <div v-if="utilisateurStore.role === enumeration.role.ADMIN" class="relative group">
        <div class="py-2 h-full bg-primary text-white hover:bg-hover-primary rounded-md cursor-pointer">
          Administration
        </div>

        <div class="absolute left-0 w-48 bg-primary border border-gray-700 rounded-md opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-opacity duration-300 z-10">
          <router-link to="/admin/bobines" class="block px-4 py-2 text-white hover:bg-gray-700">{{t("coils")}}</router-link>
          <router-link to="/admin/informations" class="block px-4 py-2 text-white hover:bg-gray-700">{{t("informations")}}</router-link>
          <router-link to="/admin/couleurs" class="block px-4 py-2 text-white hover:bg-gray-700">{{t("colors")}}</router-link>
          <router-link to="/admin/imprimantes" class="block px-4 py-2 text-white hover:bg-gray-700">{{t("printers")}}</router-link>
          <router-link to="/admin/marques" class="block px-4 py-2 text-white hover:bg-gray-700">{{t("brands")}}</router-link>
          <router-link to="/admin/typefilaments" class="block px-4 py-2 text-white hover:bg-gray-700">{{t("filamentsType")}}</router-link>
          <router-link to="/admin/contact" class="block px-4 py-2 text-white hover:bg-gray-700">{{t("messages")}}</router-link>
          <router-link to="/admin/inscription" class="block px-4 py-2 text-white hover:bg-gray-700">{{t("register")}}</router-link>
        </div>
      </div>

      <router-link v-if="utilisateurStore.isConnected" to="/commandes/create" class="text-white hover:text-hover-white">{{t("createAnOrder")}}</router-link>
      <router-link v-if="utilisateurStore.role === enumeration.role.CLIENT" to="/commandes" class="text-white hover:text-hover-white">{{t("myOrders")}}</router-link>
      <router-link v-if="utilisateurStore.role === enumeration.role.ADMIN" to="/admin/commandes" class="text-white hover:text-hover-white">{{t("orders")}}</router-link>
      <router-link to="/contact" class="text-white hover:text-hover-white">{{t("contact")}}</router-link>
    </div>

    <div class="hidden md:flex">
      <router-link to="/versions" v-if="utilisateurStore.isConnected" class="mr-2">
        <font-awesome-icon icon="fas fa-book" class="text-white text-lg" />
      </router-link>
      <router-link to="/me" v-if="utilisateurStore.isConnected">
        <font-awesome-icon icon="fas fa-user" class="text-white text-lg" />
      </router-link>
      <button v-if="utilisateurStore.isConnected" @click="logout" class="text-white hover:text-hover-white ml-2">{{t("logout")}}</button>
      <router-link v-else to="/" class="text-white hover:text-hover-white">{{t("login")}}</router-link>
    </div>
  </nav>

  <!-- Menu déroulant animé depuis la droite (inchangé) -->
  <Transition name="slide-from-right" class="z-50">
    <div v-if="isMenuOpen" class="md:hidden bg-blue-500 fixed top-0 right-0 h-full w-60 flex flex-col text-white text-xl overflow-y-scroll">
      <button @click="toggleMenu" class="text-white hover:text-hover-white ml-auto p-4">
        <font-awesome-icon icon="fas fa-times" class="text-lg"/>
      </button>

      <router-link v-if="utilisateurStore.role === enumeration.role.ADMIN" to="/admin/typefilaments" @click="toggleMenu" class="p-4 hover:bg-blue-600">{{t("filamentsType")}}</router-link>
      <router-link v-if="utilisateurStore.role === enumeration.role.ADMIN" to="/admin/marques" @click="toggleMenu" class="p-4 hover:bg-blue-600">{{t("brands")}}</router-link>
      <router-link v-if="utilisateurStore.role === enumeration.role.ADMIN" to="/admin/informations" @click="toggleMenu" class="p-4 hover:bg-blue-600">{{t("informations")}}</router-link>
      <router-link v-if="utilisateurStore.role === enumeration.role.ADMIN" to="/admin/imprimantes" @click="toggleMenu" class="p-4 hover:bg-blue-600">{{t("printers")}}</router-link>
      <router-link v-if="utilisateurStore.role === enumeration.role.ADMIN" to="/admin/couleurs" @click="toggleMenu" class="p-4 hover:bg-blue-600">{{t("colors")}}</router-link>
      <router-link v-if="utilisateurStore.role === enumeration.role.ADMIN" to="/admin/bobines" @click="toggleMenu" class="p-4 hover:bg-blue-600">{{t("coils")}}</router-link>
      <router-link v-if="utilisateurStore.isConnected" to="/commandes/create" @click="toggleMenu" class="p-4 hover:bg-blue-600">{{t("createAnOrder")}}</router-link>
      <router-link v-if="utilisateurStore.role === enumeration.role.CLIENT" to="/commandes" @click="toggleMenu" class="p-4 hover:bg-blue-600">{{t("myOrders")}}</router-link>
      <router-link v-if="utilisateurStore.role === enumeration.role.ADMIN" to="/admin/commandes" @click="toggleMenu" class="p-4 hover:bg-blue-600">{{t("orders")}}</router-link>
      <router-link v-if="utilisateurStore.role === enumeration.role.ADMIN" to="/admin/inscription" @click="toggleMenu" class="p-4 hover:bg-blue-600">{{t("register")}}</router-link>
      <router-link to="/admin/contact" v-if="utilisateurStore.role === enumeration.role.ADMIN" @click="toggleMenu" class="p-4 hover:bg-blue-600">{{t("messages")}}</router-link>
      <router-link to="/contact" @click="toggleMenu" class="p-4 hover:bg-blue-600">{{t("contact")}}</router-link>
      <router-link to="/versions" @click="toggleMenu" class="p-4 hover:bg-blue-600">{{t("versionsOnly")}}</router-link>
      <router-link to="/me" v-if="utilisateurStore.isConnected" @click="toggleMenu" class="p-4 hover:bg-blue-600">{{t("myAccount")}}</router-link>
      <button v-if="utilisateurStore.isConnected" @click="logout" class="p-4 hover:bg-blue-600">{{ t("logout") }}</button>
      <router-link v-else to="/" @click="toggleMenu" class="p-4 hover:bg-blue-600">{{t("login")}}</router-link>
    </div>
  </transition>
</template>


<script setup>

import {UtilisateurStores} from "@/stores/UtilisateurStores";
import {NotificationStores} from "@/stores/NotificationStores";
import {router} from "@/routeur";
import {enumeration} from '../../Enumeration';
import {ref} from "vue";
import {t} from "../utils/Traduction";

const utilisateurStore = UtilisateurStores();
const notificationStore = NotificationStores();
const isMenuOpen = ref(false);

const toggleMenu = () => {
  isMenuOpen.value = !isMenuOpen.value;
}

const logout = async () => {
  utilisateurStore.logout(false);

  notificationStore.addNotification(
      t("youAreDisconnected"),
      "success");

  toggleMenu();

  await router.push("/");
}

</script>

<style scoped>
.slide-from-right-enter-active {
  animation: slide-from-right-enter 0.5s;
}

.slide-from-right-leave-active {
  animation: slide-from-right-leave 0.5s;
}

@keyframes slide-from-right-enter {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slide-from-right-leave {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

</style>