<template>
  <div class="mt-8 md:mt-16 md:mx-0 mx-4 flex items-center justify-center">
    <div class="bg-white-levitation w-full md:w-2/3 lg:max-w-md  space-y-8 p-8 bg-white rounded shadow-xl">
      <h2 class="text-2xl font-semibold text-center">{{t("connection")}}</h2>

      <form @submit.prevent="login">
        <div class="mb-4">
          <label for="email" class="block text-gray-700 text-sm font-medium">{{t("email")}}</label>
          <input
              v-model="email"
              type="email"
              id="email"
              name="email"
              class="mt-1 p-3 block w-full rounded border"
              :placeholder="t('yourEmail')"
              required
          />
        </div>
        <div class="mb-4">
          <label for="password" class="block text-gray-700 text-sm font-medium">{{t('password')}}</label>
          <input
              v-model="password"
              type="password"
              id="password"
              name="password"
              minlength="6"
              class="mt-1 p-3 block w-full rounded border"
              :placeholder="t('yourPassword')"
              required
          />
        </div>
        <button type="submit" class="bg-blue-500 text-white p-3 rounded w-full">{{t("login")}}</button>
      </form>
    </div>
  </div>
</template>

<script setup>

import {ref} from "vue";
import xhr from "@/utils/xhr";
import {NotificationStores} from "@/stores/NotificationStores";
import jwt_decode from "jwt-decode";
import {router} from "@/routeur";
import {UtilisateurStores} from "@/stores/UtilisateurStores";
import {enumeration} from "../../Enumeration";
import { jsonValidator } from "@/utils/JsonValidator";
import {t} from "../utils/Traduction";

const notificationStore = NotificationStores();
const utilisateurStore = UtilisateurStores();

if(utilisateurStore.isConnected) {
  if(utilisateurStore.role === enumeration.role.ADMIN) {
    router.push({path: "/admin/commandes"});
  } else {
    router.push({path: "/commandes"});
  }
}

const email = ref("");
const password = ref("");

const login = async () => {

  xhr.addRequestToQueue("POST", "/utilisateurs/login", {email: email.value, password: password.value},
  false, false, false, async (response) => {
      if(response.code !== 200) {

        if(response.code === 0 || !jsonValidator.validate(response.response)) {
          notificationStore.addNotification(
              t("errorDuringServerConnexion"),
              "error");
        } else {
          notificationStore.addNotification(
              JSON.parse(response.response).message,
              "error");
        }
      } else {
        const info = JSON.parse(response.response);
        notificationStore.addNotification(
            info.message,
            "success");


        const jwtInfo = jwt_decode(info.token);

        utilisateurStore.login(jwtInfo.role, info.token, jwtInfo.langue);


        if(localStorage.getItem("redirectAfterLogin")) {
          await router.push({path: localStorage.getItem("redirectAfterLogin")});
          
        } else if(jwtInfo.role === enumeration.role.ADMIN) {
          await router.push({path: "/admin/commandes"});

        } else {
          await router.push({path: "/commandes"});
        }
      }
    });
}

</script>

<style scoped>

</style>