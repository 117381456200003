<template>
  <ModalCreationFailedObjectComponent :hideModal="hideCreateFailedModal" :produit="produitModalFailed" v-show="isModalCreateFailedVisible !== ''" />
  <Modal3DRender :base64-data="base64ProduitToRender3D" :uuidProduit="uuidProduitToRender3D" :hideModal="hideModal3DRender" v-show="uuidProduitToRender3D !== '' || base64ProduitToRender3D !== ''" />

  <div class="bg-gray-100 p-4 rounded-lg shadow-md">
    <div class="flex flex-col md:flex-row justify-between items-start md:items-center mb-4">
      <loading-text :texte="commandeStores.commandeSelected.nom" :is-loaded="commandeStores.commandeSelected.notLoad" type="h2"/>
      <etat-chips v-if="isMobile" :etat="commandeStores.commandeSelected.etat" />

      <div class="flex flex-col md:flex-row items-center">
        <etat-chips v-if="!isMobile" :etat="commandeStores.commandeSelected.etat" />

        <button v-show="utilisateurStore.role === enumeration.role.ADMIN
        && commandeStores.commandeSelected.etat.libelle === enumeration.etatCommande.TERMINEE" class="bg-blue-500 text-white px-4 py-1 rounded-md" @click="setToStatusPayed">Passer au status {{ enumeration.getTraductionByEtat(enumeration.etatCommande.TERMINEE_ET_PAYEE) }}</button>

        <button v-show="utilisateurStore.role === enumeration.role.ADMIN
        && (commandeStores.commandeSelected.etat.libelle === enumeration.etatCommande.EN_COURS
          || commandeStores.commandeSelected.etat.libelle === enumeration.etatCommande.A_FAIRE)
        && !commandeStores.commandeSelected.produits.some(produit => produit.etat.libelle !== enumeration.etatObjet.A_IMPRIMER)"
        class="bg-blue-500 text-white px-4 py-1 rounded-md" @click="setToStatusCancel">{{t("changeToStatus")}} {{ enumeration.getTraductionByEtat(enumeration.etatCommande.ANNULEE) }}</button>

        <div class="absolute md:relative top-20 md:top-0 right-4 md:right-0 ml-2">
          <font-awesome-icon v-show="!commandeStores.commandeSelected.estGratuite" :class="{'cursor-pointer': utilisateurStore.role === enumeration.role.ADMIN}" class="text-xl" :icon="['fas', 'euro-sign']" v-on:click="toogleEstGratuit" />
          <font-awesome-icon v-show="commandeStores.commandeSelected.estGratuite" :class="{'cursor-pointer': utilisateurStore.role === enumeration.role.ADMIN}" class="text-xl" :icon="['fab', 'creative-commons-nc-eu']" v-on:click="toogleEstGratuit" />
        </div>
      </div>
    </div>

    <div class="flex flex-col md:flex-row justify-between items-start md:items-end">
      <div>
        <loading-text :texte="t('idWithDots') + commandeStores.commandeSelected.uuid" :is-loaded="commandeStores.commandeSelected.notLoad" type="span"/>
        <loading-text :texte="t('startDateWithDots') + dateFormater" :is-loaded="commandeStores.commandeSelected.notLoad" type="span"/>
        <loading-text :texte="t('userWithDots') + commandeStores.commandeSelected.utilisateur.email" :is-loaded="commandeStores.commandeSelected.notLoad"
            :type="utilisateurStore.role === enumeration.role.ADMIN ? 'router-link' : 'span'"
            :extra="commandeStores.commandeSelected.utilisateur.uuid"/>
        <loading-text :texte="t('delivryWithDots') + commandeStores.commandeSelected.utilisateur.livraison" :is-loaded="commandeStores.commandeSelected.notLoad" type="span"/>
      </div>
      <div class="mt-4 md:mt-0" v-show="!commandeStores.commandeSelected.estGratuite">
        <div class="flex flex-row items-center">
          <font-awesome-icon class="text-green-500" v-show="commandeStores.commandeSelected.variationPrixKWH === 'low'" icon="fas fa-minus" />
          <font-awesome-icon class="text-blue-500" icon="fas fa-equals" v-show="commandeStores.commandeSelected.variationPrixKWH === 'equals'" />
          <font-awesome-icon class="text-red-500" icon="fas fa-plus" v-show="commandeStores.commandeSelected.variationPrixKWH === 'high'" />
          <loading-text :class="{'ml-4': commandeStores.commandeSelected.variationPrixKWH}" :texte="t('priceKiloWattPerHourWithDots') + commandeStores.commandeSelected.PrixElectricite" :is-loaded="commandeStores.commandeSelected.notLoad" type="span"/>
        </div>

        <div class="flex flex-row items-center">
          <font-awesome-icon class="text-green-500" icon="fas fa-minus" v-show="commandeStores.commandeSelected.variationPrixBobine === 'low'" />
          <font-awesome-icon class="text-blue-500" icon="fas fa-equals" v-show="commandeStores.commandeSelected.variationPrixBobine === 'equals'" />
          <font-awesome-icon class="text-red-500" icon="fas fa-plus" v-show="commandeStores.commandeSelected.variationPrixBobine === 'high'" />
          <loading-text :class="{'ml-4': commandeStores.commandeSelected.variationPrixBobine}" :texte="t('priceCoilKiloWithDots') + commandeStores.commandeSelected.PrixBobine" :is-loaded="commandeStores.commandeSelected.notLoad" type="span"/>
        </div>

        <loading-text v-show="commandeStores.commandeSelected.cout.production > 0" :texte="t('priceProduction') + commandeStores.commandeSelected.cout.production" :is-loaded="commandeStores.commandeSelected.notLoad" type="span"/>
        <loading-text v-show="commandeStores.commandeSelected.cout.total > 0" :texte="t('totalPrice') + commandeStores.commandeSelected.cout.total" :is-loaded="commandeStores.commandeSelected.notLoad" type="span"/>

        <button v-if="!commandeStores.commandeSelected.notLoad
                       && (commandeStores.commandeSelected.etat.libelle === enumeration.etatCommande.TERMINEE
                       || commandeStores.commandeSelected.etat.libelle === enumeration.etatCommande.TERMINEE_ET_PAYEE)"
                @click="downloadFacture" class="bg-blue-500 text-white px-4 py-1 rounded-md">
          <span v-show="!isDownloadFacture">{{t("downloadInvoice")}}</span>
          <loading-dot v-show="isDownloadFacture" />
        </button>
      </div>

    </div>
  </div>

  <div class="flex flex-row flex-wrap justify-evenly mt-8">
    <id-card-produit v-for="produit of commandeStores.commandeSelected.produits" :produit="produit"
      :etatCommande="commandeStores.commandeSelected.etat.libelle" :key="produit" class="w-[18%] h-full"
      :showCreateFailedModal="showCreateFailedModal" :show3DRenderModalByUuid="show3DRenderModalByUuid"
      :show3DRenderModalByBase64="show3DRenderModalByBase64"/>
 </div>
</template>

<script setup>

import {CommandeStores} from "@/stores/CommandeStores";
import {computed, defineProps, onMounted, ref} from 'vue';
import IdCardProduit from "@/components/IdCardProduit";
import EtatChips from "@/components/EtatChips";
import LoadingText from "@/components/LoadingText";
import {UtilisateurStores} from "@/stores/UtilisateurStores";
import xhr from "@/utils/xhr";
import {NotificationStores} from "@/stores/NotificationStores";
import {enumeration} from '../../Enumeration';
import LoadingDot from "@/components/LoadingDot";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {BobineStore} from "@/stores/BobineStore";
import { jsonValidator } from "@/utils/JsonValidator";
import {t} from "../utils/Traduction";
import {ImprimantesStore} from "@/stores/ImprimanteStore";
import ModalCreationFailedObjectComponent from "@/components/ModalCreationFailedObjectComponent.vue";
import Modal3DRender from "@/components/Modal3DRender.vue";
import {CouleurStore} from "@/stores/CouleurStore";

const props = defineProps({
  uuid: {
    type: String,
    required: true,
  },
});

const commandeStores = CommandeStores();
const utilisateurStore = UtilisateurStores();
const notificationStore = NotificationStores();
const bobineStore =  BobineStore();
const imprimanteStore = ImprimantesStore();
const couleurStore = CouleurStore();

bobineStore.loadBobines();
imprimanteStore.loadImprimantes();
couleurStore.loadCouleurs();

const isDownloadFacture = ref(false);
const isModalCreateFailedVisible = ref("");
let produitModalFailed = computed(() =>
    commandeStores.commandeSelected.produits.find(produit => produit.uuid === isModalCreateFailedVisible.value)
);
const uuidProduitToRender3D = ref("");
const base64ProduitToRender3D = ref("");


commandeStores.resetSelected();
commandeStores.getCommandeById(props.uuid);

let isMobile = false;

const checkMobile = () => {
  isMobile = window.innerWidth <= 	640;
}

onMounted(() => {
  checkMobile();
  window.addEventListener("resize", checkMobile);
});


const dateFormater = computed(() => {
  if(commandeStores.commandeSelected.dateDebut === "") {
    return '';
  }
  const dateObj = new Date(commandeStores.commandeSelected.dateDebut);

  const jour = dateObj.getUTCDate().toString().padStart(2, '0');
  const mois = (dateObj.getUTCMonth() + 1).toString().padStart(2, '0');
  const annee = dateObj.getUTCFullYear();

  return `${jour}-${mois}-${annee}`;
});

const showCreateFailedModal = (uuidProduit) => {
  isModalCreateFailedVisible.value = uuidProduit;
}

const show3DRenderModalByUuid = (uuidProduit) => {
  uuidProduitToRender3D.value = uuidProduit;
}

const show3DRenderModalByBase64 = (base64) => {
  base64ProduitToRender3D.value = base64;
}

const hideCreateFailedModal = () => {
  isModalCreateFailedVisible.value = "";
}

const hideModal3DRender = () => {
  uuidProduitToRender3D.value = "";
  base64ProduitToRender3D.value = "";
}

const downloadFacture = async () => {
  if(isDownloadFacture.value) {
    return;
  }

  isDownloadFacture.value = true;

  xhr.addRequestToQueue("GET", `/commandes/${commandeStores.commandeSelected.uuid}/pdf`, undefined,
  true, true, true, (response) => {
    if(response.code !== 200) {

      if(response.code === 0 || !jsonValidator.validate(response.response)) {
        notificationStore.addNotification(
            t("errorDuringDownloadInvoice"),
            "error");
      } else {
        notificationStore.addNotification(
            JSON.parse(response.response).message,
            "error");
      }
    } else {
      const blob = new Blob([response.response], {type: 'application/pdf'});

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', commandeStores.commandeSelected.nom  + '.pdf');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      isDownloadFacture.value = false;
    }
  });
}

const setToStatusPayed = async () => {

  xhr.addRequestToQueue("PUT", `/commandes/${commandeStores.commandeSelected.uuid}/etat`,
  {etat: enumeration.etatCommande.TERMINEE_ET_PAYEE}, true, true, false, (response) => {
    if(response.code !== 200) {

      if(response.code === 0 || !jsonValidator.validate(response.response)) {
        notificationStore.addNotification(
            t("errorDuringServerConnexion"),
            "error");
      } else {
        notificationStore.addNotification(
            JSON.parse(response.response).message,
            "error");
      }
    } else {
      notificationStore.addNotification(
          t("orderStatusUpdated"),
          "success");

      commandeStores.updateCommandeStatusBySelected(enumeration.etatCommande.TERMINEE_ET_PAYEE);
    }
  });
}

const setToStatusCancel = async () => {

  xhr.addRequestToQueue("PUT", `/commandes/${commandeStores.commandeSelected.uuid}/etat`,
  {etat: enumeration.etatCommande.ANNULEE}, true, true, false, (response) => {
    if(response.code !== 200) {

      if(response.code === 0 || !jsonValidator.validate(response.response)) {
        notificationStore.addNotification(
            t("errorDuringServerConnexion"),
            "error");
      } else {
        notificationStore.addNotification(
            JSON.parse(response.response).message,
            "error");
      }
    } else {
      notificationStore.addNotification(
          t("orderStatusUpdated"),
          "success");

      commandeStores.updateCommandeStatusBySelected(enumeration.etatCommande.ANNULEE);
      commandeStores.commandeSelected.produits.forEach(produit => {
        produit.etat.libelle = enumeration.etatObjet.ANNULE;
      });
    }
  });
}

const toogleEstGratuit = async () => {

  if(utilisateurStore.role !== enumeration.role.ADMIN) {
    return;
  }

  xhr.addRequestToQueue("PUT", `/commandes/${commandeStores.commandeSelected.uuid}/free`, undefined, true, true, false, (response) => {
    if(response.code !== 200) {

      if(response.code === 0 || !jsonValidator.validate(response.response)) {
        notificationStore.addNotification(
            t("errorDuringServerConnexion"),
            "error");
      } else {
        notificationStore.addNotification(
            JSON.parse(response.response).message,
            "error");
      }
    } else {
      commandeStores.commandeSelected.estGratuite = !commandeStores.commandeSelected.estGratuite;

      notificationStore.addNotification(
          JSON.parse(response.response).message,
          "success");

      if(commandeStores.commandeSelected.estGratuite && commandeStores.commandeSelected.etat.libelle === enumeration.etatCommande.TERMINEE) {
        commandeStores.updateCommandeStatusBySelected(enumeration.etatCommande.TERMINEE_ET_PAYEE);
      } else if(!commandeStores.commandeSelected.estGratuite && commandeStores.commandeSelected.etat.libelle === enumeration.etatCommande.TERMINEE_ET_PAYEE) {
        commandeStores.updateCommandeStatusBySelected(enumeration.etatCommande.TERMINEE);
      }

      commandeStores.updateCommandeIsFreeBySelected(commandeStores.commandeSelected.estGratuite);
    }
  });
}

</script>

<style scoped>

</style>