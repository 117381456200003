<template>
  <ModalSuppresionMarqueComponent v-if="marqueStore.showModalDelete" :close-modal="closeModalDelete" />

  <div class="flex flex-col lg:flex-row items-between gap-4 m-4">
    <input v-model="newName" class="border border-black px-2 rounded" :placeholder="t('brandName')"/>
    <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" v-on:click="createMarque">{{ t('addAction') }}</button>
  </div>

  <div class="flex flex-row gap-16 md:gap-4 justify-evenly flex-wrap my-16">
    <idCardMarque v-for="marque in marqueStore.marques" :key="marque.uuid" :marque="marque" :show-modal-delete="showModalDelete" />
  </div>

  <span v-if="marqueStore.marques.length === 0" class="absolute left-1/2 top-1/2 translate-x-[-50%] translate-y-[-50%] text-3xl text-center">
    {{t("noBrand")}}
  </span>
</template>

<script setup>

import {ref} from "vue";
import {MarqueStore} from "@/stores/MarqueStore";
import IdCardMarque from "@/components/IdCardMarque.vue";
import {NotificationStores} from "@/stores/NotificationStores";
import {t} from "@/utils/Traduction";
import {UtilisateurStores} from "@/stores/UtilisateurStores";
import {enumeration} from "../../Enumeration";
import {router} from "@/routeur";
import ModalSuppresionMarqueComponent from "@/components/ModalSuppresionMarqueComponent.vue";

const marqueStore = MarqueStore();
const notificationStores = NotificationStores();
const utilisateurStore = UtilisateurStores();

if(utilisateurStore.role !== enumeration.role.ADMIN) {
  utilisateurStore.logout(false);
  notificationStores.addNotification(t("youNeedToBeAdminToAccessThisPage"), 'error');
  router.push({ path: "/" });
}


marqueStore.loadMarques();

const uuidMarqueModale = ref(0);

const closeModalDelete = (supprimer) => {
  if(supprimer) {
    marqueStore.deleteMarque(uuidMarqueModale.value);
  }

  marqueStore.showModalDelete = false;
}

const showModalDelete = (uuid) => {
  uuidMarqueModale.value = uuid;
  marqueStore.showModalDelete = true;
}

const newName = ref("");

const createMarque = () => {
  if(newName.value === "") {
    notificationStores.addNotification(t("needFillBrandName"), 'error');
    return;
  }

  marqueStore.createMarque(newName.value);
  newName.value = "";
}

</script>

<style scoped>

</style>