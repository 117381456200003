import { defineStore } from 'pinia'
import xhr from "@/utils/xhr";
import {NotificationStores} from "@/stores/NotificationStores";
import {CommandeStores} from "@/stores/CommandeStores";
import {enumeration} from "../../Enumeration";
import {t} from "@/utils/Traduction";

export const UtilisateurStores = defineStore("UtilisateurStores", {
    state: () => ({
        isConnected: localStorage.getItem('token') != null,
        role: localStorage.getItem('role'),
        rolesAvailable: [],
        clients: [],
    }),
    actions: {
        login(role, token, langue) {
            localStorage.setItem('token', token);
            localStorage.setItem('role', role);
            localStorage.setItem('langue', langue);
            this.isConnected = true;
            this.role = role;
        },
        logout(keepRedirect = true) {
            const commandeStore = CommandeStores();

            commandeStore.commandes = [];
            commandeStore.commandesOffset = 0;
            commandeStore.hasMore = true;

            localStorage.setItem("keepRedirect", keepRedirect);
            localStorage.removeItem('token');
            localStorage.removeItem('role');
            localStorage.removeItem('langue');
            this.isConnected = false;
            this.role = null;
        },
        async loadRoles() {
            if(this.rolesAvailable.length === 0) {
                const notificationStore = NotificationStores();

                xhr.addRequestToQueue("GET", "/roles", undefined, true, true, false,
                (response) => {
                    if (response.code !== 200) {
                        if (response.code === 0 || JSON.parse(response.response)) {
                            notificationStore.addNotification(
                                t("errorDuringGetRoles"),
                                "error");
                        } else {
                            notificationStore.addNotification(
                                JSON.parse(response.response).message,
                                "error");
                        }
                    } else {
                        this.rolesAvailable = JSON.parse(response.response);
                    }
                });
            }
        },
        async loadClients() {
            const notificationStore = NotificationStores();

            if(this.role !== enumeration.role.ADMIN) {
                return;
            }

            if(this.clients.length === 0) {

                xhr.addRequestToQueue("GET", "/utilisateurs/clients", undefined, true, true, false,
                (response) => {
                    if (response.code !== 200) {
                        if (response.code === 0 || JSON.parse(response.response)) {
                            notificationStore.addNotification(
                                t("errorDuringGetRoles"),
                                "error");
                        } else {
                            notificationStore.addNotification(
                                JSON.parse(response.response).message,
                                "error");
                        }
                    } else {
                        this.clients = JSON.parse(response.response);
                    }
                });
            }
        },
        async getStatsByUser(uuidUtilisateur) {
            return new Promise((resolve, reject) => {
                const notificationStore = NotificationStores();

                xhr.addRequestToQueue("GET", "/utilisateurs/stats/" + uuidUtilisateur, undefined, true, true, false,
                    (response) => {
                        if(response.code !== 200) {
                            if(response.code === 0 || JSON.parse(response.response)) {
                                notificationStore.addNotification(
                                    t("errorDuringGetStats"),
                                    "error");

                                reject();
                            } else {
                                notificationStore.addNotification(
                                    JSON.parse(response.response).message,
                                    "error");

                                reject();
                            }
                        } else {
                            resolve(JSON.parse(response.response));
                        }
                    });
            });
        }
    },
    getters : {}
});
