<template>
  <ModalCreateReparation v-show="showCreateReparationModal" :close-modal="closeModalCreationReparation" />

  <div class="flex flex-col md:flex-row items-center justify-center gap-8 bg-white py-4 border-b-2 shadow-xl rounded-b-[2.5rem]"
  :class="{'border-red-600': !estEnBenefice, 'border-green-600': estEnBenefice}">
    <img :src="stats.value.imprimante.urlImage" alt="" class="h-64">
    <div class="flex flex-col items-start">
      <span class="text-3xl">{{t("model")}} : {{ stats.value.imprimante.modele }}</span>
      <span class="text-xl">{{t("brand")}} : {{ stats.value.imprimante.marque }}</span>
      <span class="text-1xl mb-4 lg:mb-0">{{t("consommation")}} : {{ stats.value.imprimante.consommation }} Watts</span>
      <span class="text-1xl mb-4 lg:mb-0">{{t("initialPriceWithDots")}} {{ stats.value.imprimante.prix }} €</span>
      <span class="text-1xl mb-4 lg:mb-0">{{t("repairPriceWithDots")}} {{ coutReparations }} €</span>
      <button @click="createReparation" class="text-blue-500">{{t("addRepair")}}</button>
    </div>
  </div>

  <div class="mt-4 flex flex-row justify-center items-center" @click="() => {showReparation = !showReparation}"
       v-show="stats.value.imprimante.reparations && stats.value.imprimante.reparations.length > 0">
    <span class="mr-2 select-none">{{t("showRepairs")}}</span>
    <font-awesome-icon icon="fas fa-chevron-down" v-show="!showReparation"/>
    <font-awesome-icon icon="fas fa-chevron-up" v-show="showReparation"/>
  </div>

  <div v-show="showReparation" class="flex flex-row flex-wrap gap-4 w-[80%] ml-[10%]">
    <IdCardReparation v-for="reparation in stats.value.imprimante.reparations" :reparationProps="reparation" :key="reparation.uuid" :delete-reparation="supprimerReparation"/>
  </div>


  <div class="flex flex-col md:flex-row flex-wrap items-center justify-center mx-4 md:mx-16 mt-8">
    <div class="p-2 w-full md:w-1/2 lg:w-1/3">
      <div class="bg-white shadow-lg rounded-lg p-6 hover:shadow-xl transition-shadow duration-300">
        <h3 class="text-lg font-semibold text-gray-700 border-b-2 border-blue-500 mb-4">{{t("price")}} :</h3>
        <div class="mt-2">
          <p class="text-gray-700"><span class="font-medium text-gray-600">{{t("priceProduction")}}</span> {{ converter.round(stats.value.stats.coutImpression) }} €</p>
          <p class="text-gray-700"><span class="font-medium text-gray-600">{{t("avgProductPriceParPiece")}}</span> {{ converter.round(stats.value.stats.avgCoutParPiece) }} €</p>
          <p class="text-gray-700"><span class="font-medium text-gray-600">{{t("profit")}}</span> {{ converter.round(stats.value.stats.beneficeImpression) }} €</p>
        </div>
      </div>
    </div>

    <div class="p-2 w-full md:w-1/2 lg:w-1/3">
      <div class="bg-white shadow-lg rounded-lg p-6 hover:shadow-xl transition-shadow duration-300">
        <h3 class="text-lg font-semibold text-gray-700 border-b-2 border-blue-500 mb-4">{{t("timeWithDots")}}</h3>
        <div class="mt-2">
          <p class="text-gray-700"><span class="font-medium text-gray-600">{{t("totalTime")}}</span> {{ converter.minToHours(stats.value.stats.tempsTotalImpression) }}</p>
          <p class="text-gray-700"><span class="font-medium text-gray-600">{{t("avgTimeParPiece")}}</span> {{ converter.minToHours(stats.value.stats.avgTempsParPiece) }}</p>
        </div>
      </div>
    </div>

    <div class="p-2 w-full md:w-1/2 lg:w-1/3">
      <div class="bg-white shadow-lg rounded-lg p-6 hover:shadow-xl transition-shadow duration-300">
        <h3 class="text-lg font-semibold text-gray-700 border-b-2 border-blue-500 mb-4">{{t("consumption")}}</h3>
        <div class="mt-2">
          <p class="text-gray-700"><span class="font-medium text-gray-600">{{t("consumptionKwh")}}</span> {{ converter.round(stats.value.stats.nbKwhUtiliser) }} KW/H</p>
          <p class="text-gray-700"><span class="font-medium text-gray-600">{{t("totalPriceKwhConsumption")}}</span> {{ converter.round(stats.value.stats.prixKwhConsommer) }} €</p>
        </div>
      </div>
    </div>


    <div class="p-2 w-full md:w-1/2 lg:w-1/3">
      <div class="bg-white shadow-lg rounded-lg p-6 hover:shadow-xl transition-shadow duration-300">
        <h3 class="text-lg font-semibold text-gray-700 border-b-2 border-blue-500 mb-4">{{t("products")}} :</h3>
        <div class="mt-2">
          <p class="text-gray-700"><span class="font-medium text-gray-600">{{t("numberOfProducts")}}</span> {{ stats.value.stats.nbTotalPieces }}</p>
        </div>
      </div>
    </div>
  </div>

  <div class="w-full flex flex-row justify-center mt-8">
    <div class="w-3/4 md:w-1/2 lg:w-1/4" v-show="stats.value.stats.couleurs.length > 0">
      <span>{{t("coilUsage")}}</span>
      <canvas id="bobineCouleur" width="100%" height="100%" class="mt-4"></canvas>
    </div>
  </div>

  <div class="flex flex-col md:flex-row mx-8 md:mx-16 mt-8 mb-16 items-center justify-center">
    <div class="w-full lg:w-1/2 md:pl-16 md:pr-8">
      <span>{{t("productFor3Years")}}</span>

      <div>
        <canvas id="produitsChart" width="100%" height="75%"></canvas>
      </div>
    </div>
  </div>
</template>

<script setup>

import {UtilisateurStores} from "@/stores/UtilisateurStores";
import {enumeration} from "../../Enumeration";
import {t} from "@/utils/Traduction";
import {router} from "@/routeur";
import {NotificationStores} from "@/stores/NotificationStores";
import {ArcElement, Chart, Legend, PieController, registerables, Tooltip} from "chart.js";
import {computed, defineProps, onMounted, reactive, ref, watch} from "vue";
import {ImprimantesStore} from "@/stores/ImprimanteStore";
import converter from "@/utils/Converter";
import ModalCreateReparation from "@/components/ModalCreateReparation.vue";
import xhr from "@/utils/xhr";
import {jsonValidator} from "@/utils/JsonValidator";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import IdCardReparation from "@/components/IdCardReparation.vue";

const utilisateurStore = UtilisateurStores();
const notificationStore = NotificationStores();

if(utilisateurStore.role !== enumeration.role.ADMIN) {
  utilisateurStore.logout(false);
  notificationStore.addNotification(t("youNeedToBeAdminToAccessThisPage"), 'error');
  router.push({ path: "/"});
}

const imprimanteStore = ImprimantesStore();

Chart.register(PieController, ArcElement, Tooltip, Legend, ...registerables);

const props = defineProps({
  uuid: {
    type: String,
    required: true,
  },
});


let stats = reactive({value: {
    imprimante: {uuid: "", marque: "", modele: "", consommation: 0, reparations: []},
    stats: {
      tempsTotalImpression: 0,
      avgTempsParPiece: 0,
      nbTotalPieces: 0, // piece
      coutImpression: 0,
      beneficeImpression: 0,
      avgCoutParPiece: 0,
      nbKwhUtiliser: 0,
      prixKwhConsommer: 0,
      couleurs: [],
      produitImprimerParMois: {},
    }
  }});

const coutReparations = computed(() => {
  return stats.value.imprimante.reparations.reduce((acc, reparation) => acc + reparation.prix, 0);
});

imprimanteStore.getStatsByUuidPrinter(props.uuid).then((response) => {
  stats.value = response;
});

const estEnBenefice = computed(() => {
  return stats.value.stats.beneficeImpression > (stats.value.imprimante.prix + coutReparations.value);
});

const showCreateReparationModal = ref(false);
const showReparation = ref(false);

const createReparation = () => {
  showCreateReparationModal.value = true;
}

const closeModalCreationReparation = (reparation) => {

  if(showCreateReparationModal.value) {
    showCreateReparationModal.value = false;
  }

  if(reparation) {
    xhr.addRequestToQueue("POST", "/reparations",  { ...reparation, uuidImprimante: props.uuid }, true, true, false,
      (response) => {
        if(response.code !== 201) {
          if (response.code === 0 || !jsonValidator.validate(response.response)) {
            notificationStore.addNotification(
                t("errorDuringCreateRepair"),
                "error");
          } else {
            notificationStore.addNotification(
                JSON.parse(response.response).message,
                "error");
          }
        } else {
          stats.value.imprimante.reparations.unshift(JSON.parse(response.response));
          notificationStore.addNotification(t("repairCreated"), "success");
        }
      });
  }
}

const supprimerReparation = (uuid) => {
  stats.value.imprimante.reparations = stats.value.imprimante.reparations.filter((reparation) => reparation.uuid !== uuid);
}

const bobineChart = ref(null);
const produitChart = ref(null);

onMounted(() => {

  watch(stats, () => {
    // chart pie pour les couleurs
    let data = {
      datasets: [{
        data: stats.value.stats.couleurs.map((couleur) => couleur.total),
        backgroundColor: stats.value.stats.couleurs.map((couleur) => couleur.code)
      }],
      labels:stats.value.stats.couleurs.map((couleur) => couleur.libelle)
    };

    let config = {
      type: 'pie',
      data: data,
      options: {
        responsive: true, plugins: {legend: {display: false}}},
    };

    if(bobineChart.value) {
      bobineChart.value.destroy();
    }

    bobineChart.value = new Chart(
        document.getElementById('bobineCouleur'),
        config
    );

    // fonctiéon transformation dict en chat dataset
    const chartData = (dict) => {
      const labels = [t("january"), t("february"), t("march"), t("april"), t("may"), t("june"),
        t("july"), t("august"), t("september"), t("october"), t("november"), t("december")];

      const currentYear = new Date().getFullYear();
      const yearsLabels = [currentYear - 2, currentYear - 1, currentYear];

      const datasets = [
        {label: yearsLabels[0], data: [], backgroundColor: 'rgba(255, 99, 132, 0.5)'},
        {label: yearsLabels[1], data: [], backgroundColor: 'rgba(54, 162, 235, 0.5)'},
        {label: yearsLabels[2], data: [], backgroundColor: 'rgba(255, 206, 86, 0.5)'},
      ];

      labels.forEach((label, index) => {
        const month = index + 1;
        datasets.forEach(dataset => {
          const year = dataset.label;
          const monthKey = `${month < 10 ? '0' + month : month}-${year}`;
          const value = dict[monthKey] || 0;
          dataset.data.push(value > 0 ? value: 0.1);
        });
      });
      return {labels, datasets};
    }

    // chart bar pour les produits
    const ctxProduit = document.getElementById('produitsChart').getContext('2d');

    if(produitChart.value) {
      produitChart.value.destroy();
    }

    produitChart.value = new Chart(ctxProduit, {
      type: 'bar',
      data: chartData(stats.value.stats.produitImprimerParMois),
      options: {
        scales: {
          y: {
            beginAtZero: true,
            max: Math.max(...Object.values(stats.value.stats.produitImprimerParMois)) + 1,
          }
        }
      }
    });
  });
});




</script>

<style scoped>

</style>