import { createApp } from 'vue'
import App from './App.vue'
import './assets/index.css'
import {router} from './routeur.js'
import {createPinia} from "pinia";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faTimes,
    faArrowRight,
    faCheck,
    faSquarePlus,
    faSquareMinus,
    faUser,
    faBars,
    faEdit,
    faTrash,
    faBook,
    faPlus,
    faMinus,
    faEquals,
    faCircleExclamation,
    faTriangleExclamation,
    faChevronDown,
    faChevronUp,
    faEuroSign
} from "@fortawesome/free-solid-svg-icons";
import {faCreativeCommonsNcEu} from "@fortawesome/free-brands-svg-icons";

const pinia = createPinia();

library.add(faTimes, faArrowRight, faCheck, faSquarePlus, faSquareMinus, faUser, faBars, faEdit, faTrash,
    faBook, faPlus, faMinus, faEquals, faCircleExclamation, faTriangleExclamation, faChevronDown, faChevronUp, faEuroSign, faCreativeCommonsNcEu);

createApp(App)
    .use(router)
    .use(pinia)
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app')
