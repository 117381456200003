<template>
  <ModalSuppresionImprimanteComponent :imprimante="selectedImprimante" :close-modal="onCloseModalDelete" v-show="showModalDel"/>

  <div class="mt-4 ml-4 flex gap-2 items-center flex-col lg:flex-row">
    <label for="marque" class="w-1/2 lg:w-auto">
      <input id="marque" :placeholder="t('brand')" class="w-full border border-black px-2 py-1 rounded"
          v-model="newMarque">
    </label>

    <label for="model" class="w-1/2 lg:w-auto">
      <input id="model" :placeholder="t('model')" class="w-full border border-black px-2 py-1 rounded"
             v-model="newModel">
    </label>

    <label for="consommation" class="w-1/2 lg:w-auto">
      <input type="number" id="consommation" :placeholder="t('consommation')" class="w-full border border-black px-2 py-1 rounded"
             v-model="newConsommation">
    </label>

    <label for="prix" class="w-1/2 lg:w-auto">
      <input type="number" id="prix" :placeholder="t('price')" class="w-full border border-black px-2 py-1 rounded"
             v-model="newPrix">
    </label>

    <label for="urlImage" class="w-1/2 lg:w-auto">
      <input id="urlImage" :placeholder="t('urlImgPrinter')" class="w-full border border-black px-2 py-1 rounded"
             v-model="newUrlImage">
    </label>

    <button class="w-1/2 lg:w-auto bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" v-on:click="createImprimante">{{ t('addAction') }}</button>
  </div>

  <div class="flex flex-row flex-wrap gap-8 w-full justify-center my-8">
    <IdCardImprimante v-for="imprimante in imprimanteStore.imprimantes" :key="imprimante.id" :imprimante="imprimante" :show-modal-delete="showModalDelete" />
  </div>
</template>

<script setup>
import {enumeration} from "../../Enumeration";
import {t} from "@/utils/Traduction";
import {router} from "@/routeur";
import {NotificationStores} from "@/stores/NotificationStores";
import {UtilisateurStores} from "@/stores/UtilisateurStores";
import {ImprimantesStore} from "@/stores/ImprimanteStore";
import IdCardImprimante from "@/components/IdCardImprimante.vue";
import {ref} from "vue";
import ModalSuppresionImprimanteComponent from "@/components/ModalSuppresionImprimanteComponent.vue";

const notificationStore = NotificationStores();
const utilisateurStore = UtilisateurStores();
const imprimanteStore = ImprimantesStore()

if(utilisateurStore.role !== enumeration.role.ADMIN) {
  utilisateurStore.logout(false);
  notificationStore.addNotification(t('youNeedToBeAdminToAccessThisPage'), 'error');
  router.push({ path: "/" });
}

imprimanteStore.loadImprimantes();

const newMarque = ref("");
const newModel = ref("");
const newConsommation = ref(0);
const newPrix = ref(0);
const newUrlImage = ref("");

const showModalDel = ref(false);
const selectedImprimante = ref({marque: "", modele: ""});

const showModalDelete = (imprimante) => {
  selectedImprimante.value = imprimante;
  showModalDel.value = true;
}

const onCloseModalDelete = (deleteImprimante) => {
  if(deleteImprimante) {
    imprimanteStore.deleteImprimante(selectedImprimante.value.uuid);
  }
  showModalDel.value = false;
  selectedImprimante.value = {marque: "", modele: ""};
}

const createImprimante = () => {

  if(newMarque.value === "" || newModel.value === "" || newConsommation.value === 0 || newPrix.value === 0 || newUrlImage.value === "") {
    notificationStore.addNotification(t('needFillAllFields'), 'error');
    return;
  }

  imprimanteStore.createImprimante({
    marque: newMarque.value,
    modele: newModel.value,
    consommation: newConsommation.value,
    prix: newPrix.value,
    urlImage: newUrlImage.value
  }, () => {
    newMarque.value = "";
    newModel.value = "";
    newConsommation.value = 0;
    newPrix.value = 0;
    newUrlImage.value = "";
  });
}

</script>

<style scoped>

</style>