import {enumeration} from "../../Enumeration";

const utils = {};

utils.getRandomColorHexa = () => {
  const r = Math.floor(Math.random() * 255);
  const g = Math.floor(Math.random() * 255);
  const b = Math.floor(Math.random() * 255);
  const a = 0.7; // Opacité
  return `rgba(${r},${g},${b},${a})`;
}

utils.calculatePrixProductionCommande = (commande) => {
  let nbGramme = 0;
  let nbKWHUtilise = 0;

  for(let i = 0; i < commande.produits.length; i++) {
    if(!commande.produits[i].temp) {
      commande.produits[i].temp = 0;
    }

    if(!commande.produits[i].poid) {
      commande.produits[i].poid = 0;
    }

    if(commande.produits[i].etat.libelle !== enumeration.etatObjet.IMPRIMEE) {
      continue;
    }

    nbGramme += (commande.produits[i].poid * commande.produits[i].nombre);

    const tempsProduit = commande.produits[i].temp * commande.produits[i].nombre;

    nbKWHUtilise += (commande.produits[i].imprimante.consommation * (tempsProduit / 60));
  }

  nbKWHUtilise = nbKWHUtilise / 1000;
  const poidsBobineEnKilo = nbGramme / 1000;
  const prixBobineTotal = poidsBobineEnKilo * commande.PrixBobine;
  const prixKWHTotal = nbKWHUtilise * commande.PrixElectricite
  return prixKWHTotal + prixBobineTotal;
}

utils.calculatePrixTotalCommande = (commande) => {
  const coutTotalProduction = utils.calculatePrixProductionCommande(commande);

  let nbPiece = 0;
  for(const produit of commande.produits) {
    if(produit.etat.libelle !== enumeration.etatObjet.IMPRIMEE) {
      continue;
    }

    nbPiece += produit.nombre;
  }

  return (coutTotalProduction + nbPiece) * enumeration.constant.COEFICIENT;
}

export { utils };
