import constant from "../../Constant.js";
import {UtilisateurStores} from "@/stores/UtilisateurStores";
import {router} from "@/routeur";

const xhr = {};

const xhrRequestList = [];
let isRunning = false;

xhr.addRequestToQueue = (method, url, data, isAuth, needCheck401, isBlob, callback) => {
    xhrRequestList.push({method: method, url: url, data: data, isAuth: isAuth, needCheck401: needCheck401, isBlob: isBlob, callback: callback});
    runNextIfPossible();
}

const sendNext = (request) => {

    let url = constant.baseUrl + request.url;

    if (request.method === 'GET' && request.data) {
        const queryString = new URLSearchParams(request.data).toString();
        url += '?' + queryString;
    }

    const xhRequest = new XMLHttpRequest();
    xhRequest.open(request.method, url);

    if(request.isAuth) {
        xhRequest.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('token'));
    }

    if(request.isBlob) {
        xhRequest.responseType = 'blob';
    }

    xhRequest.setRequestHeader('Content-Type', 'application/json');
    xhRequest.setRequestHeader('Access-Control-Allow-Origin', '*');
    xhRequest.setRequestHeader('Access-Control-Allow-Methods', 'GET, POST, PUT, DELETE');

    xhRequest.onload = async () => {
        storeNewToken(xhRequest);
        isRunning = false;
        runNextIfPossible();

        if(request.needCheck401 && await check401(xhRequest.status)) {
            return;
        }

        if(request.isBlob) {
            request.callback({response: xhRequest.response, code: xhRequest.status});
        } else {
            request.callback({response: xhRequest.responseText, code: xhRequest.status});
        }
    }
    xhRequest.onerror = () => {request.callback({response: xhRequest.responseText, code: xhRequest.status});}

    if(request.method !== 'GET' && request.data) {
        xhRequest.send(JSON.stringify(request.data));
    } else {
        xhRequest.send();
    }
}

const runNextIfPossible = () => {
    if(!isRunning && xhrRequestList.length > 0) {
        isRunning = true;
        sendNext(xhrRequestList[0]);
        xhrRequestList.shift();
    }
}

const check401 = async (code) => {
    if(code === 401) {
        const utilisateurStore = UtilisateurStores();

        utilisateurStore.logout();
        await router.push({'path': '/'});
        return true;
    }

    return false;
}

const storeNewToken = (xhRequest) => {
    const token = xhRequest.getResponseHeader('Authorization');
    if(token) {
        localStorage.setItem('token', token);
    }
}

export default xhr;