<template>
  <ModalSuppresionTypeFilamentComponent v-if="typeFilamentStore.showModalDelete" :close-modal="closeModalDelete" />

  <div class="flex flex-col lg:flex-row items-between gap-4 m-4">
    <input v-model="newLibelle" class="border border-black px-2 rounded" :placeholder="t('newLabelTypeOfFilament')"/>
    <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" v-on:click="createTypeFilament">{{ t('addAction') }}</button>
  </div>

  <div class="flex flex-row gap-16 md:gap-4 justify-evenly flex-wrap my-16">
    <IdCardTypeDeFilament v-for="type in typeFilamentStore.typeFilaments" :key="type.uuid" :type-filament="type" :show-modal-delete="showModalDelete" />
  </div>

  <span v-if="typeFilamentStore.typeFilaments.length === 0" class="absolute left-1/2 top-1/2 translate-x-[-50%] translate-y-[-50%] text-3xl text-center">
    {{t("noTypeFilament")}}
  </span>
</template>

<script setup>

import {ref} from "vue";
import {NotificationStores} from "@/stores/NotificationStores";
import {t} from "@/utils/Traduction";
import {UtilisateurStores} from "@/stores/UtilisateurStores";
import {enumeration} from "../../Enumeration";
import {router} from "@/routeur";
import IdCardTypeDeFilament from "@/components/IdCardTypeDeFilament.vue";
import {TypeFilamentStore} from "@/stores/TypeFilamentStore";
import ModalSuppresionTypeFilamentComponent from "@/components/ModalSuppresionTypeFilamentComponent.vue";

const typeFilamentStore = TypeFilamentStore();
const notificationStores = NotificationStores();
const utilisateurStore = UtilisateurStores();

if(utilisateurStore.role !== enumeration.role.ADMIN) {
  utilisateurStore.logout(false);
  notificationStores.addNotification(t("youNeedToBeAdminToAccessThisPage"), 'error');
  router.push({ path: "/" });
}


typeFilamentStore.loadTypeFilaments();

const uuidTypeFilamentModale = ref(0);

const closeModalDelete = (supprimer) => {
  if(supprimer) {
    typeFilamentStore.deleteTypeFilament(uuidTypeFilamentModale.value);
  }

  typeFilamentStore.showModalDelete = false;
}

const showModalDelete = (uuid) => {
  uuidTypeFilamentModale.value = uuid;
  typeFilamentStore.showModalDelete = true;
}

const newLibelle = ref("");

const createTypeFilament = () => {
  if(newLibelle.value === "") {
    notificationStores.addNotification(t("needFillBrandName"), 'error');
    return;
  }

  typeFilamentStore.createTypeFilament(newLibelle.value);
  newLibelle.value = "";
}

</script>

<style scoped>

</style>