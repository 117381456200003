<template>
  <div class="container mx-auto p-4">

    <div class="flex flex-col lg:flex-row items-between">
      <input type="number" min="1" :placeholder="t('weightInGrammes')" class="border border-black px-2 py-1 rounded"
          v-model="newBobine.poidsRestant">

      <select
          v-model="newBobine.uuidCouleur"
          id="couleur"
          class="px-3 py-2 block rounded border border-black mt-4 mb-4 lg:my-0 lg:ml-4">
        <option value="-1" disabled selected>{{t("selectAColor")}}</option>
        <option v-for="couleur of couleurStore.couleurs" :key="couleur" :value="couleur.uuid">{{ couleur.libelle }}</option>
      </select>

      <select
          v-model="newBobine.uuidMarque"
          id="marque"
          class="px-3 py-2 block rounded border border-black mt-4 mb-4 lg:my-0 lg:ml-4">
        <option value="-1" disabled selected>{{t("selectABrand")}}</option>
        <option v-for="marque of marqueStore.marques" :key="marque" :value="marque.uuid">{{ marque.nom }}</option>
      </select>

      <select
          v-model="newBobine.uuidTypeFilament"
          id="marque"
          class="px-3 py-2 block rounded border border-black mt-4 mb-4 lg:my-0 lg:mx-4">
        <option value="-1" disabled selected>{{t("selectAFilament")}}</option>
        <option v-for="typeFilament of typeFilamentStore.typeFilaments" :key="typeFilament" :value="typeFilament.uuid">{{ typeFilament.libelle }}</option>
      </select>

      <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" v-on:click="createBobine">{{ t('addAction') }}</button>
    </div>

    <div class="stats mt-6">
      <h2 class="text-lg font-bold">{{t("statsPerColor")}}</h2>
      <button @click="createCouleur" class="text-blue-500 mt-1">{{t("addColor")}}</button>
      <div v-for="(objets, couleur) in statistiquesParCouleur.value" :key="couleur">
        <span class="font-semibold">{{ objets.libelle }}:</span> {{ objets.poidsRestant }} g
      </div>
    </div>

    <h1 class="text-xl font-bold mb-4 mt-4">{{t("bobineList")}}</h1>

    <div class="flex flex-row items-center">
      <h2 class="text-lg font-semibold">{{t("filter")}}</h2>
      <select
          v-model="filtreBobine"
          class="mt-1 px-1 block rounded border border-black mt-4 mb-4 lg:my-0 mx-4">
        <option value="-1">{{t("all")}}</option>
        <option v-for="couleur of couleurAvailableForFilter.value" :key="couleur.uuid" :value="couleur.uuid">{{ couleur.libelle }}</option>
      </select>
    </div>

    <div class="flex flex-row items-center">
      <h2 class="text-lg font-semibold">{{t("sort")}}:</h2>

      <select class="mt-1 px-1 block rounded border border-black mt-4 mb-4 lg:my-0 mx-4"
              v-model="trieBobine">
        <option disabled>{{t("chooseSort")}}</option>
        <option value="number" selected>{{t("sortByNumber")}}</option>
        <option value="weight">{{t("sortByWeight")}}</option>
      </select>

      <select class="mt-1 px-1 block rounded border border-black mt-4 mb-4 lg:my-0 mx-4"
              v-model="typeTrie">
        <option disabled>{{t("chooseSort")}}</option>
        <option value="asc" selected>{{t("asc")}}</option>
        <option value="desc">{{t("desc")}}</option>
      </select>
    </div>

    <div class="flex flex-wrap -m-2">
      <id-card-bobine-component v-for="bobine of bobines.value" :key="bobine.uuid" :bobine="bobine" :show-modal-delete="showModalDelete" />
    </div>

    <ModalSuppresionBobineComponent v-if="showModal" :close-modal="closeModalDelete" :grammes="grammesModale"/>
    <modal-creation-couleur-component v-show="couleurStore.showModalCreation"/>
  </div>
</template>

<script setup>
import {UtilisateurStores} from "@/stores/UtilisateurStores";
import {NotificationStores} from "@/stores/NotificationStores";
import {BobineStore} from "@/stores/BobineStore";
import {enumeration} from "../../Enumeration";
import {router} from "@/routeur";
import ModalSuppresionBobineComponent from "@/components/ModalSuppresionBobineComponent";
import {computed, reactive, ref, watch} from "vue";
import {CouleurStore} from "@/stores/CouleurStore";
import IdCardBobineComponent from "@/components/IdCardBobineComponent";
import ModalCreationCouleurComponent from "@/components/ModalCreationCouleurComponent.vue";
import {t} from "../utils/Traduction";
import {MarqueStore} from "@/stores/MarqueStore";
import {TypeFilamentStore} from "@/stores/TypeFilamentStore";

const utilisateurStore = UtilisateurStores();
const notificationStore = NotificationStores();
const bobineStore = BobineStore();
const couleurStore = CouleurStore();
const marqueStore = MarqueStore();
const typeFilamentStore = TypeFilamentStore();

if(utilisateurStore.role !== enumeration.role.ADMIN) {

  utilisateurStore.logout(false);
  notificationStore.addNotification(t("youNeedToBeAdminToAccessThisPage"), 'error');
  router.push({ path: "/"});
}

const grammesModale = ref(0);
const uuidBobineModale = ref(0);
const showModal = ref(false);
const statistiquesParCouleur = reactive({});
const filtreBobine = ref(-1);
const trieBobine = ref('number');
const typeTrie = ref('asc');
const bobines = reactive([]);
const couleurAvailableForFilter = reactive([]);

const newBobine = {
  poidsRestant: 1000,
  uuidCouleur: -1,
  uuidMarque: -1,
  uuidTypeFilament: -1
};

bobineStore.loadBobines();
couleurStore.loadCouleurs();
marqueStore.loadMarques();
typeFilamentStore.loadTypeFilaments();

watch(() => filtreBobine.value, () => {
  applyFilterAndSort();
}, { deep: true });

watch(() => trieBobine.value, () => {
  applyFilterAndSort();
}, { deep: true });

watch(() => typeTrie.value, () => {
  applyFilterAndSort();
}, { deep: true });

// Surveillez également les changements dans le tableau des bobines
watch(() => bobineStore.bobines, () => {
  applyFilterAndSort();
}, { deep: true });

// Définissez une fonction pour appliquer le filtre
function applyFilterAndSort() {
  bobines.value = bobineStore.bobines.filter(bobine =>
      bobine.couleur.uuid === filtreBobine.value || filtreBobine.value === -1
  );

  bobines.value.sort((a, b) => {
    if(trieBobine.value === 'number') {
      return typeTrie.value === 'asc' ? a.nombre - b.nombre : b.nombre - a.nombre;
    } else {
      return typeTrie.value === 'asc' ? a.poidsRestant - b.poidsRestant : b.poidsRestant - a.poidsRestant;
    }
  });
}

watch(() => bobineStore.bobines, () => {
  const stats = {};
  bobineStore.bobines.forEach(bobine => {
    if (!stats[bobine.couleur.uuid]) {
      stats[bobine.couleur.uuid] = {poidsRestant: 0, libelle: bobine.couleur.libelle};
    }
    stats[bobine.couleur.uuid].poidsRestant += bobine.poidsRestant;
  });
  statistiquesParCouleur.value = stats;

  bobines.value = bobineStore.bobines;
}, { deep: true, immediate: true });

couleurAvailableForFilter.value = computed(() => {
  return couleurStore.couleurs.filter(couleur =>
      bobineStore.bobines.find(bobine => bobine.couleur.uuid === couleur.uuid)
  );
});

const closeModalDelete = (supprimer) => {
  if(supprimer) {
    bobineStore.deleteBobine(uuidBobineModale.value);
    bobineStore.bobines = bobineStore.bobines.filter(bobine => bobine.uuid !== uuidBobineModale.value);
  }

  showModal.value = false;
}

const showModalDelete = (uuid, grammes) => {
  grammesModale.value = grammes;
  showModal.value = true;
  uuidBobineModale.value = uuid;
}

const createBobine = () => {
  if(newBobine.poidsRestant <= 1) {
    notificationStore.addNotification(t("remeningWeightInfZero"), 'error');
    return;
  }

  if(newBobine.uuidCouleur === -1) {
    notificationStore.addNotification(t("youNeedToSelectAColor"), 'error');
    return;
  }

  if(newBobine.uuidMarque === -1) {
    notificationStore.addNotification(t("youNeedToSelectABrand"), 'error');
    return;
  }

  bobineStore.createBobine(newBobine);
}

const createCouleur = () => {
  couleurStore.showModalCreation = true;
}

</script>

<style scoped>

</style>