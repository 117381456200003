<template>
  <div class="mt-8 md:mt-16 flex items-center justify-center md:mx-0 mx-4">
    <div class="bg-white-levitation w-full md:w-2/3 lg:max-w-md  space-y-8 p-8 bg-white rounded shadow-xl">
      <h2 class="text-2xl font-semibold text-center">{{t("inscriptionForm")}}</h2>

      <form @submit.prevent="register">
        <div class="mb-4">
          <label for="email" class="block text-gray-700 text-sm font-medium">{{t("email")}}</label>
          <input
              v-model="email"
              type="email"
              id="email"
              name="email"
              class="mt-1 p-3 block w-full rounded border"
              :placeholder="t('yourEmail')"
              required
          />
        </div>
        <div class="mb-4">
          <label for="password" class="block text-gray-700 text-sm font-medium">{{t("password")}}</label>
          <input
              v-model="password"
              type="password"
              id="password"
              name="password"
              class="mt-1 p-3 block w-full rounded border"
              :placeholder="t('password')"
              required
          />
        </div>
        <div class="mb-4">
          <label for="confirmPassword" class="block text-gray-700 text-sm font-medium">{{t("confirmPassword")}}</label>
          <input
              v-model="confirmPassword"
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              class="mt-1 p-3 block w-full rounded border"
              :placeholder="t('confirmPassword')"
              required
          />
        </div>
        <div class="mb-4">
          <label for="role" class="block text-gray-700 text-sm font-medium">{{t("role")}}</label>
          <select
              v-model="selectedRole"
              id="role"
              name="role"
              class="mt-1 p-3 block w-full rounded border"
              required
          >
            <option value="" disabled>{{t("chooseARole")}}</option>
            <option v-for="roleAvailable of utilisateurStore.rolesAvailable" :value="roleAvailable.uuid" :key="roleAvailable">{{ roleAvailable.libelle }}</option>

          </select>
        </div>
        <div class="mb-4">
          <label for="address" class="block text-gray-700 text-sm font-medium">{{t("delivryAdresse")}}</label>
          <input
              v-model="livraison"
              id="address"
              name="livraison"
              class="mt-1 p-3 block w-full rounded border"
              :placeholder="t('delivry')"
              required
          />
        </div>
        <div class="mb-4">
          <label for="langue" class="block text-gray-700 text-sm font-medium">{{t("language")}}</label>
          <select
              v-model="langue"
              id="langue"
              name="langue"
              class="mt-1 p-3 block w-full rounded border"
              required
          >
            <option value="" disabled>{{t("chooseALanguage")}}</option>
            <option value="fr">{{t("french")}}</option>
            <option value="en">{{t("english")}}</option>
            <option value="hi">{{t("hindi")}}</option>
            <option value="zh">{{t("chinese")}}</option>
            <option value="es">{{t("spanish")}}</option>
            <option value="ar">{{t("arabic")}}</option>
            <option value="bn">{{t("bengali")}}</option>
            <option value="pt">{{t("portuguese")}}</option>
            <option value="ru">{{t("russian")}}</option>
            <option value="ur">{{t("urdu")}}</option>

          </select>
        </div>
        <button type="submit" class="bg-blue-500 text-white p-3 rounded w-full">{{t("registerMe")}}</button>
      </form>
    </div>
  </div>
</template>

<script setup>

import {UtilisateurStores} from "@/stores/UtilisateurStores";
import {router} from "@/routeur";
import {NotificationStores} from "@/stores/NotificationStores";
import {ref} from "vue";
import xhr from "@/utils/xhr";
import {enumeration} from '../../Enumeration';
import { jsonValidator } from "@/utils/JsonValidator";
import {t} from "../utils/Traduction";

const utilisateurStore = UtilisateurStores();
const notificationStore = NotificationStores();

if(utilisateurStore.role !== enumeration.role.ADMIN) {

  utilisateurStore.logout(false);
  notificationStore.addNotification(t('youNeedToBeAdminToAccessThisPage'), 'error');
  router.push({ path: "/"});
}

utilisateurStore.loadRoles();

const email = ref('');
const password = ref('');
const confirmPassword = ref('');
const selectedRole = ref('');
const livraison = ref('');
const langue = ref('');

const register = async () => {
  if(password.value !== confirmPassword.value) {
    notificationStore.addNotification(t("passwordNotMatch"), 'error');
    return;
  }

  xhr.addRequestToQueue("POST", "/utilisateurs/register",  {
    email: email.value, password: password.value, confirmPassword: confirmPassword.value,
    uuidRole: selectedRole.value, livraison: livraison.value, langue: langue.value}, true, true, false,
    (response) => {
      if (response.code !== 201) {
        if (response.code === 0 || !jsonValidator.validate(response.response)) {
          notificationStore.addNotification(
              t("errorDuringRegisterUser"),
              "error");
        } else {
          notificationStore.addNotification(
              JSON.parse(response.response).message,
              "error");
        }
      } else {
        notificationStore.addNotification(
            t("userRegisterSuccess"),
            "success");

        email.value = '';
        password.value = '';
        confirmPassword.value = '';
        selectedRole.value = '';
        livraison.value = '';
        langue.value = '';
      }
    });
}

</script>

<style scoped>

</style>